import GltfLoader from "./GltfLoader";
export default class GltfIO {
    constructor(io) {
        this._ioImpl = io;
    }
    loadGltf(path, options = {}) {
        const loader = new GltfLoader(this._ioImpl, path, options);
        return loader.load();
    }
}
